import { api, baseUrl } from "../config/server";

const API_ROOT_URL = api + baseUrl;
const getHeaders = (language) => {
  const lan = language || localStorage.getItem("lan") || navigator.language;
  const headers = {
    "Content-Type": "application/json",
    "Accept-Language": lan,
  };
  return headers;
};

const getAuthHeaders = (language) => {
  const lan = language || localStorage.getItem("lan") || navigator.language;
  const apiKeyAS = localStorage.getItem("typApiKey");
  let apiKey = "";

  if (apiKeyAS) {
    apiKey = JSON.parse(apiKeyAS);
  }

  return {
    ...getHeaders(),
    Authorization: "Bearer " + apiKey,
    "Accept-Language": lan,
  };
};

const requests = {
  // SESSIONS
  login: (credentials) => {
    return {
      url: `${API_ROOT_URL}/users/login`,
      method: "POST",
      headers: getHeaders(),
      data: credentials,
    };
  },
  getUser: () => {
    return {
      url: `${API_ROOT_URL}/users/me`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getUserDataForContract: () => {
    return {
      url: `${API_ROOT_URL}/me/contracts`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  //SHIPPINGS

  getAllShippings: (params) => {
    return {
      url: `${API_ROOT_URL}/shipments`,
      method: "GET",
      headers: getAuthHeaders(),
      params,
    };
  },
  getShippingsByFilters: (query) => {
    return {
      url: `${API_ROOT_URL}/shipments`,
      method: "GET",
      headers: getAuthHeaders(),
      params: query,
    };
  },
  getShippingById: (id) => {
    return {
      url: `${API_ROOT_URL}/shipments/${id}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  stopShipping: (id) => {
    return {
      url: `${API_ROOT_URL}/shipments/${id}/suspend`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
  getTrackingById: (id) => {
    return {
      url: `${API_ROOT_URL}/tracking/${id}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  createShipping: (data) => {
    return {
      url: `${API_ROOT_URL}/shipments`,
      method: "POST",
      headers: getAuthHeaders(),
      data,
    };
  },
  getLabel: (id) => {
    return {
      url: `${API_ROOT_URL}/shipments/${id}/labels/PDF`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getPodPdf: (id) => {
    return {
      url: `${API_ROOT_URL}/pods/${id}/pdf`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // SERVICES

  getServices: (params) => {
    return {
      url: `${API_ROOT_URL}/services`,
      method: "GET",
      headers: getAuthHeaders(),
      params,
    };
  },

  // SLOTS

  getSlots: (data) => {
    return {
      url: `${API_ROOT_URL}/slots/shipment?availability=true`,
      method: "POST",
      headers: getAuthHeaders(),
      data,
    };
  },
  getSlotsByAddress: (data) => {
    return {
      url: `${API_ROOT_URL}/slots/address?availability=true`,
      method: "POST",
      headers: getAuthHeaders(),
      data,
    };
  },

  //BOOKING

  createBooking: (data) => {
    return {
      url: `${API_ROOT_URL}/bookings`,
      method: "POST",
      headers: getAuthHeaders(),
      data,
    };
  },

  // RETURNS

  addReturn: (data) => {
    return {
      url: `${API_ROOT_URL}/returns`,
      method: "POST",
      headers: getAuthHeaders(),
      data,
    };
  },

  getParcels: (id) => {
    return {
      url: `${API_ROOT_URL}/shipments/${id}/parcels`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // CONTRACTS

  getContractById: (id) => {
    return {
      url: `${API_ROOT_URL}/contracts/${id}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getSubcontractById: (id) => {
    return {
      url: `${API_ROOT_URL}/contracts/sub-contracts/${id}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  getAccountById: (id) => {
    return {
      url: `${API_ROOT_URL}/accounts/${id}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // STORAGES

  getAllStorages: (params) => {
    return {
      url: `${API_ROOT_URL}/warehouse-storages`,
      method: "GET",
      headers: getAuthHeaders(),
      params,
    };
  },
  getStorageById: (id) => {
    return {
      url: `${API_ROOT_URL}/warehouse-storages/${id}`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getAllReleaseModes: () => {
    return {
      url: `${API_ROOT_URL}/warehouse-storages/release/modes`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getAllReasons: () => {
    return {
      url: `${API_ROOT_URL}/warehouse-storages/reasons`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  saveNewReleaseProvision: (id) => {
    return {
      url: `${API_ROOT_URL}/warehouse-storages/${id}/provision`,
      method: "POST",
      headers: getAuthHeaders(),
    };
  },
  getProvision: (id) => {
    return {
      url: `${API_ROOT_URL}/warehouse-storages/${id}/provision`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },

  // EVENTS

  getEventsList: () => {
    return {
      url: `${API_ROOT_URL}/events/codes`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getCurrenciesList: () => {
    return {
      url: `${API_ROOT_URL}/currencies`,
      method: "GET",
      headers: getAuthHeaders(),
      params: {
        isEnabled: true,
      },
    };
  },
  getCodModes: () => {
    return {
      url: `${API_ROOT_URL}/cods/modes`,
      method: "GET",
      headers: getAuthHeaders(),
    };
  },
  getCSV: (params) => {
    return {
      url: `${API_ROOT_URL}/shipments/csv`,
      method: "GET",
      headers: getAuthHeaders(),
      params,
    };
  },
};

export default requests;
